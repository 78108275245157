import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SEO from "../components/seo"

const StyleSheet = styled.div`
  color: #666;
  // & > h2 {
  //   font-size: 20px;
  //   margin-top: 24px;
  //   margin-bottom: 12px;
  //   color: darkslategrey;
  // }
  & > p {
    line-height: 1.2;
    margin-bottom: 10px;
  }
  & a {
    color: darkslategrey;
  }  
`

const CVPage = ({ data }) => {
  return (
    <div>
      <Layout active={'cv'}>
        <SEO title="CV" />
        <StyleSheet>
          <ReactMarkdown children={data.file.childMarkdownRemark.rawMarkdownBody} />
        </StyleSheet>
      </Layout>
    </div>
  )
}

export default CVPage

export const pageQuery = graphql`
  query {
    file(name: {eq: "cv"}) {
      childMarkdownRemark {
        rawMarkdownBody
        frontmatter {
          title
        }
      }
    }
  }
`
